// This file was generated by modules-webmake (modules for web) project.
// See: https://github.com/medikoo/modules-webmake

(function (modules) {
	'use strict';

	var resolve, getRequire, wmRequire, notFoundError, findFile
	  , extensions = {".js":[".coffee",".jsx",".es6",".es"],".json":[],".css":[],".html":[]}
	  , envRequire = typeof require === 'function' ? require : null;

	notFoundError = function (path) {
		var error = new Error("Could not find module '" + path + "'");
		error.code = 'MODULE_NOT_FOUND';
		return error;
	};
	findFile = function (scope, name, extName) {
		var i, ext;
		if (typeof scope[name + extName] === 'function') return name + extName;
		for (i = 0; (ext = extensions[extName][i]); ++i) {
			if (typeof scope[name + ext] === 'function') return name + ext;
		}
		return null;
	};
	resolve = function (scope, tree, path, fullPath, state, id) {
		var name, dir, exports, module, fn, found, ext;
		path = path.split('/');
		name = path.pop();
		if ((name === '.') || (name === '..')) {
			path.push(name);
			name = '';
		}
		while ((dir = path.shift()) != null) {
			if (!dir || (dir === '.')) continue;
			if (dir === '..') {
				scope = tree.pop();
				id = id.slice(0, id.lastIndexOf('/'));
			} else {
				tree.push(scope);
				scope = scope[dir];
				id += '/' + dir;
			}
			if (!scope) throw notFoundError(fullPath);
		}
		if (name && (typeof scope[name] !== 'function')) {
			found = findFile(scope, name, '.js');
			if (!found) found = findFile(scope, name, '.json');
			if (!found) found = findFile(scope, name, '.css');
			if (!found) found = findFile(scope, name, '.html');
			if (found) {
				name = found;
			} else if ((state !== 2) && (typeof scope[name] === 'object')) {
				tree.push(scope);
				scope = scope[name];
				id += '/' + name;
				name = '';
			}
		}
		if (!name) {
			if ((state !== 1) && scope[':mainpath:']) {
				return resolve(scope, tree, scope[':mainpath:'], fullPath, 1, id);
			}
			return resolve(scope, tree, 'index', fullPath, 2, id);
		}
		fn = scope[name];
		if (!fn) throw notFoundError(fullPath);
		if (fn.hasOwnProperty('module')) return fn.module.exports;
		exports = {};
		fn.module = module = { exports: exports, id: id + '/' + name };
		fn.call(exports, exports, module, getRequire(scope, tree, id));
		return module.exports;
	};
	wmRequire = function (scope, tree, fullPath, id) {
		var name, path = fullPath, t = fullPath.charAt(0), state = 0;
		if (t === '/') {
			path = path.slice(1);
			scope = modules['/'];
			if (!scope) {
				if (envRequire) return envRequire(fullPath);
				throw notFoundError(fullPath);
			}
			id = '/';
			tree = [];
		} else if (t !== '.') {
			name = path.split('/', 1)[0];
			scope = modules[name];
			if (!scope) {
				if (envRequire) return envRequire(fullPath);
				throw notFoundError(fullPath);
			}
			id = name;
			tree = [];
			path = path.slice(name.length + 1);
			if (!path) {
				path = scope[':mainpath:'];
				if (path) {
					state = 1;
				} else {
					path = 'index';
					state = 2;
				}
			}
		}
		return resolve(scope, tree, path, fullPath, state, id);
	};
	getRequire = function (scope, tree, id) {
		return function (path) {
			return wmRequire(scope, [].concat(tree), path, id);
		};
	};
	return getRequire(modules, [], '');
})({
	"/": {
		"Volumes": {
			"Data": {
				"manGoweb": {
					"Projects": {
						"travnikynovotny-wp": {
							"theme": {
								"scripts": {
									"index.js": function (exports, module, require) {
										eval("/**\n\tVariables\n*/\nvar dc = $(document)\nvar win = $(window)\nvar body = $(document.body)\n\nvar desktop = win.width() > 990\n\n/**\n\tInits\n*/\n$('.gallery-icon').magnificPopup({\n  delegate: 'a',\n  type: 'image',\n  gallery: {\n      enabled: true\n    }\n});\n\n$('.js-iframe').magnificPopup({\n  type: 'iframe'\n});\n\n$('.js-gallerybox').bxSlider({\n  mode: 'fade',\n  pager: false,\n  controls: false,\n  auto: true,\n  touchEnabled: false\n});\n\n/**\n\tbinds\n*/\n$('.js-nav-toggle').on('click', function(e){\n\tbody.toggleClass('nav-is-open')\n\te.preventDefault()\n})\n\n\n/**\n\ttranslate magnifi\n*/\n$.extend(true, $.magnificPopup.defaults, {\n  tClose: 'Zavřít (Esc)', // Alt text on close button\n  tLoading: 'Načítám...', // Text that is displayed during loading. Can contain %curr% and %total% keys\n  gallery: {\n    tPrev: 'Předchozí (Šipka doleva)', // Alt text on left arrow\n    tNext: 'Další (Šipka doprava)', // Alt text on right arrow\n    tCounter: '%curr% z %total%' // Markup for \"1 of 7\" counter\n  },\n  image: {\n    tError: '<a href=\"%url%\">Fotku</a> se nepovedlo načíst.' // Error message when image could not be loaded\n  },\n  ajax: {\n    tError: '<a href=\"%url%\">Obsah</a> se nepovedlo načíst.' // Error message when ajax request failed\n  }\n});\n\n\n//# sourceURL=/Volumes/Data/manGoweb/Projects/travnikynovotny-wp/theme/scripts/index.js");
									}
								}
							}
						}
					}
				}
			}
		}
	}
})("/Volumes/Data/manGoweb/Projects/travnikynovotny-wp/theme/scripts/index");
